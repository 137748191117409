import { sanitizeHtml } from './sanitize-html'

/**
 * Initialise all GLOBAL directives
 */
export const initialiseDirectives = function (app: OBR.Global.App) {
    app.directive('sanitize-html', sanitizeHtml)

    return app
}
