/**
 * Element ID of the left menu to get element height in iframe
 */
export const APP_LEFT_MENU_EL_ID: string = 'app-left-menu'

/**
 * URL param key to set redirect url
 * e.g. search param `?redirect=false`
 */
export const APP_QUERY_PARAM_REDIRECT: string = 'redirect'

/**
 * Test URLs in Prod Environment
 */
export const APP_TEST_URLS = ['dev.racebetsint', 'stage.racebetsint']
