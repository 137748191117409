/**
 * Focus outline visible only for keyboard navigation
 */
export function focusOnlyKeyboard() {
    document.body.classList.add('using-mouse')
    document.body.addEventListener('mousedown', () => {
        document.body.classList.add('using-mouse')
    })
    document.body.addEventListener('keydown', (event) => {
        const tabKeyCode = 9

        if (event.keyCode === tabKeyCode) {
            document.body.classList.remove('using-mouse')
        }
    })
}
