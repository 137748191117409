<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

export interface Props {
    component?: string
    name: string
}

const props = withDefaults(defineProps<Props>(), {
    component: 'SvgIcon',
    isColored: false,
})

const icon = defineAsyncComponent(() => {
    if (props.component === 'SvgIcon') {
        return import(`./assets/${props.name}.svg`)
    } else {
        return import(`../${props.component}/assets/${props.name}.svg`)
    }
})
</script>

<template>
    <component :is="icon" class="inline-block"></component>
</template>
