import { routesLocale } from '@obr-core/router/locale-routes'
import { errorLoadingFile } from '@obr-core/router/hooks'

export const routeConfig: OBR.Router.RouteRecordRaw[] = [
    {
        path: '/',
        redirect: { name: 'million-game' },
    },
    {
        path: `/${routesLocale.offers_million}`,
        component: () =>
            import(
                /* webpackChunkName: "offers.million" */
                '@obr-ui/components/Offers/TheMillion/TheMillion.vue'
            ),
        redirect: { name: 'million-game' },
        meta: {
            i18n_modules: ['million'],
        },
        children: [
            {
                path: routesLocale.offers_million_game,
                name: 'million-game',
                component: () =>
                    import(
                        /* webpackChunkName: "offers.million.game" */
                        '@obr-ui/components/Offers/TheMillion/TheMillionGame.vue'
                    ).catch(errorLoadingFile),
            },
            {
                path: routesLocale.offers_million_history,
                name: 'million-history',
                component: () =>
                    import(
                        /* webpackChunkName: "offers.million.history" */
                        '@obr-ui/components/Offers/TheMillion/TheMillionHistory.vue'
                    ).catch(errorLoadingFile),
                meta: {
                    auth: true,
                },
            },
            {
                path: routesLocale.offers_million_leaderboard,
                name: 'million-leaderboard',
                component: () =>
                    import(
                        /* webpackChunkName: "offers.million.leaderboard" */
                        '@obr-ui/components/Offers/TheMillion/TheMillionLeaderboard.vue'
                    ).catch(errorLoadingFile),
            },
            {
                path: routesLocale.offers_million_faq,
                name: 'million-faq',
                component: () =>
                    import(
                        /* webpackChunkName: "offers.million.faq" */
                        '@obr-ui/components/Offers/TheMillion/TheMillionFaq.vue'
                    ).catch(errorLoadingFile),
            },
            {
                path: routesLocale.offers_million_terms,
                name: 'million-terms',
                component: () =>
                    import(
                        /* webpackChunkName: "offers.million.terms" */
                        '@obr-ui/components/Offers/TheMillion/TheMillionTerms.vue'
                    ).catch(errorLoadingFile),
            },
        ],
    },
]
