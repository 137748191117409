import dompurify from 'dompurify'
import {
    SETTINGS_DOMPURIFY_DEFAULT_CONFIG,
    SETTINGS_DOMPURIFY_SVG_CONFIG,
    SETTINGS_DOMPURIFY_HTML_CONFIG,
} from '@obr-core/config/settings'

/**
 * Get DOMPurify according to directive argument
 * @param {string} dirArg
 * @returns {Object} DOMPurify config
 */
const getConfig = function (dirArg: string = ''): DOMPurify.Config {
    // this list can be extended
    // full list of config options: https://github.com/cure53/DOMPurify#can-i-configure-dompurify

    switch (dirArg) {
        case 'svg':
            return SETTINGS_DOMPURIFY_SVG_CONFIG
        case 'html':
            return SETTINGS_DOMPURIFY_HTML_CONFIG
        default:
            return SETTINGS_DOMPURIFY_DEFAULT_CONFIG
    }
}

/**
 * Directive inserted and update function
 */
const updateComponent: OBR.Global.FunctionDirective = function (
    el: HTMLElement,
    binding: OBR.Global.DirectiveBinding
): void {
    // if inner html has not changed - return
    if (binding.oldValue === binding.value) {
        return
    }

    // toString is used to avoid TS error because sanitize can return not only string
    // https://github.com/cure53/DOMPurify/issues/361
    el.innerHTML = dompurify
        .sanitize(binding.value, getConfig(binding.arg))
        .toString()
}

/**
 * sanitize-html directive
 */
export const sanitizeHtml: OBR.Global.ObjectDirective = {
    mounted: updateComponent,
    updated: updateComponent,
}
