import { postMessageService } from '@obr-core/services/PostMessageService'
import { POST_MESSAGE_ID_NAVIGATION } from '@obr-core/config/post-messages'
import { getMatchedModules } from '@obr-core/helpers/i18n.helpers'
import { I18nService } from '@obr-core/services/I18nService'
import { userStoreService, i18nStoreService } from '@obr-core/services/store'
import { isIframe } from '@obr-core/helpers/app.helpers'
import { ENV_RB, RBEnv } from '@obr-core/config/env'
import { getItem, setItem } from '@obr-core/lib/storage.manager'
import { getMillionHostUri } from '@obr-core/helpers/brand-settings.helpers'
import { STORAGE_KEY_MILLION_REDIRECT } from '@obr-core/config/storage'
import {
    APP_QUERY_PARAM_REDIRECT,
    APP_TEST_URLS,
} from '@obr-core/config/iframe-ui'

/**
 * Router hook after each routing event
 * @param to Target Route Object being navigated to
 * @param from Current route being navigated away from
 */
export function onAfterEach(
    to: OBR.Router.Route /* , from: OBR.Router.Route */
) {
    postMessageService.send(POST_MESSAGE_ID_NAVIGATION, to.path)
}

/**
 * Global before guards are called in creation order, whenever a navigation is triggered.
 * Guards may be resolved asynchronously, and the navigation is considered pending before
 * all hooks have been resolved.
 *
 * @param to Target Route Object being navigated to
 * @param from Current route being navigated away from
 * @param next Must be called to resolve hook
 */
export function onBeforeEach(
    to: OBR.Router.Route,
    from: OBR.Router.Route,
    next: (to?: OBR.Router.RouteLocationRaw | false | void) => void
): void {
    const loggedIn = userStoreService.isLoggedIn()

    const searchParams = new URLSearchParams(window.location.search)
    const redirectQueryParams = searchParams.get(APP_QUERY_PARAM_REDIRECT)

    // Middleware for set redirect debug cookie to prevent iframe url redirection to host app
    if (isIframe() && ENV_RB === RBEnv.PRODUCTION) {
        // if million_redirect and the value is false set the localstorage value to false
        if (redirectQueryParams && redirectQueryParams === 'false') {
            setItem(STORAGE_KEY_MILLION_REDIRECT, 'false')
        } else if (redirectQueryParams && redirectQueryParams === 'true') {
            setItem(STORAGE_KEY_MILLION_REDIRECT, 'true')
        }

        const isTestURL = APP_TEST_URLS.some((host) =>
            window.location.hostname.includes(host)
        )

        // redirect iframe url to host app (unless redirect cookie is set)
        if (
            !isTestURL &&
            window.self === window.top &&
            getItem(STORAGE_KEY_MILLION_REDIRECT) !== 'false'
        ) {
            const lang = userStoreService.language()
            const hostURI = getMillionHostUri(lang)

            window.location.href = `${hostURI}`

            return
        }
    }

    // Auth Guard
    if (to.matched.some((record) => record.meta.auth === true) && !loggedIn) {
        userStoreService.authRequired()
        next(false)
        return
    }
    // Visible Route Guard
    if (to.matched.some((record) => record.meta.visible === false)) {
        next('/')
        return
    }

    // Get i18n modules required by all matching routes
    const i18nService = I18nService.getInstance()
    const i18nModulesRequired = i18nService.getRequiredModules(
        getMatchedModules(to.matched)
    )

    if (i18nModulesRequired.length > 0) {
        i18nStoreService
            .loadModules({ modules: i18nModulesRequired })
            .then(() => {
                next()
            })
    } else {
        next()
    }
}

/**
 * Similar to `router.beforeEach`, with the difference that resolve guards will be called
 * right before the navigation is confirmed, after all in-component guards and async route
 * components are resolved
 * @param to Target Route Object being navigated to
 * @param from Current route being navigated away from
 * @param next Must be called to resolve hook
 */
export function onBeforeResolve(
    to: OBR.Router.Route,
    from: OBR.Router.Route,
    next: (to?: OBR.Router.RouteLocationRaw | false | void) => void
): void {
    next()
}
