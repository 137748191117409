import { ParticipantStatus } from '@obr-core/config/the-million'
import { useApp } from '@obr-core/store/app'
import { useMillion } from '@obr-core/store/million'
import { useUser } from '@obr-core/store/user'

/**
 * The Million Service
 *
 * is used as separated project and also as part of OBR
 * supports multiple brands
 */
class TheMillionService {
    /**
     * Initialize million game
     *
     * e.g. load once initialy
     */
    public async onInit(): Promise<void> {
        await useMillion().onInit()
    }

    /**
     * Checks if user can participate
     */
    public canUserParticipate(): boolean {
        return useMillion().canUserParticipate
    }

    /**
     * Checks if "App" or "User" is loading
     *
     * e.g. /bootsrap request and /me request
     */
    public isLoadingMe(): boolean {
        return useApp().loading || useUser().loading
    }

    /**
     * Checks if "Million" is loading
     */
    public isLoading(): boolean {
        return useMillion().isLoading
    }

    /**
     * Checks if error page should be visible
     */
    public isErrorPage(): boolean {
        return useMillion().getErrorCode > 0
    }

    /**
     * Checks if game is ended
     */
    public isEndGame(): boolean {
        return useMillion().isEndGame
    }

    /**
     * Checks if new game is started
     */
    public isNewGame(): boolean {
        return useMillion().isNewGame
    }

    /**
     * Checks if game is open
     */
    public isOpenGame(): boolean {
        return useMillion().isOpenGame
    }

    /**
     * Checks if turnover is required
     */
    public isTurnoverRequired(): boolean {
        return useMillion().isRequiredTurnover
    }

    public isDepositRequired(): boolean {
        return useMillion().isDepositRequired
    }
    // check not user status but additional "required_deposit" field
    public isDepositRequiredGame(): boolean {
        return useMillion().getRequiredDepositGame
    }

    public isFreeGamePage(): boolean {
        return useMillion().isFreeGamePage
    }

    /**
     * Checks if participant is logged out
     */
    public isLoggedOut(): boolean {
        return useMillion().isLoggedOut
    }

    /**
     * Checks if participant is logged in
     */
    public isUserLoggedIn(): boolean {
        return useUser().logged_in
    }

    /**
     * Checks if participant is eligible

     */
    public isUserEligible(): boolean {
        return useMillion().isEligible
    }

    /**
     * Checks if leaderboard page should be shown
     */
    public showLeaderboard(): boolean {
        return useMillion().showLeaderboard
    }

    /**
     * Checks if question exists
     */
    public isQuestionExist(): boolean {
        return useMillion().isQuestionExist
    }

    /**
     * Checks if question exists
     */
    public isQuestionOpen(): boolean {
        return useMillion().isQuestionOpen as boolean
    }

    /**
     * Checks if question is locked
     */
    public isQuestionLocked(): boolean {
        return useMillion().isQuestionLocked
    }

    /**
     * Gets error code
     */
    public getErrorCode(): number {
        return useMillion().getErrorCode
    }

    /**
     * Gets current question
     */
    public getCurrentQuestion(): OBR.TheMillion.CurrentQuestion | null {
        return useMillion().getQuestion
    }

    /**
     * Gets currency
     */
    public getCurrency(): string {
        return useMillion().getCurrency
    }

    /**
     * Gets current turnover
     */
    public getCurrentTurnover(): number {
        return useMillion().getCurrentTurnover
    }

    /**
     * Gets required turnover amount
     */
    public getRequiredTurnover(): number {
        return useMillion().getRequiredTurnover
    }

    /**
     * Gets number of questions
     */
    public getNumQuestions(): number {
        return useMillion().getQuestionsNum
    }

    /**
     * Gets number of questions
     *
     * e.g. in case if number of question is 0 return 1
     */
    public getQuestionNum(): number {
        return useMillion().getQuestionNum || 1
    }

    /**
     * Gets current question text
     */
    public getQuestionText(): string {
        return useMillion().getQuestionText
    }

    /**
     * Gets current question deadline
     */
    public getQuestionDeadline(): number | null {
        return useMillion().getQuestionDeadline
    }

    /**
     * Gets current question ID
     */
    public getQuestionID(): string | null {
        return useMillion().getQuestionID
    }

    /**
     * Gets stakes
     *
     * e.g. stake yes and no
     */
    public getStakes(): {
        yes: number
        no: number
    } {
        const yes = useMillion().getStakeYes
        const no = useMillion().getStakeNo

        if (yes === 0 && no === 0) {
            const val = Math.round(this.getUserMoney() / 2)

            return {
                yes: val,
                no: val,
            }
        }

        return {
            yes,
            no,
        }
    }

    /**
     * Gets participant balance
     */
    public getParticipantBalance(): number {
        return useMillion().getParticipantBalance
    }

    /**
     * Gets participant ID
     */
    public getParticipantID(): string | null {
        return useMillion().getParticipantID
    }

    /**
     * Gets game prize
     */
    public getGamePrize(): number {
        return useMillion().getGamePrize
    }

    /**
     * Gets user money
     *
     * i.e. if game is new gets game prize otherwise gets participant balance
     */
    public getUserMoney(): number {
        return this.isNewGame()
            ? this.getGamePrize()
            : this.getParticipantBalance()
    }

    /**
     * Checks if split is loading
     */
    public isSplitLoading(): boolean {
        return useMillion().isSplitLoading
    }

    /**
     * Checks if guide is completed
     */
    public isGuideCompleted(): boolean {
        return useMillion().isGuideCompleted
    }

    /**
     * Gets participant status
     */
    public getParticipantStatus(): ParticipantStatus {
        return useMillion().getParticipantStatus
    }

    /**
     * Gets last participant balance
     */
    public getLastParticipantBalance(): number {
        return useMillion().getLastParticipantBalance || 0
    }

    /**
     * Gets last participant ID
     */
    public getLastParticipantID(): string {
        return useMillion().getLastParticipantID
    }

    /**
     * Gets started game date
     */
    public getStartedGameDate(): number {
        return useMillion().getStartGameDate || 0
    }

    /**
     * Gets optedIn date
     */
    public getOptedInDate(): number {
        return useMillion().getOptInDate || 0
    }

    /**
     * Action to require authentication
     */
    public requireAuth() {
        useUser().onAuthRequired()
    }

    /**
     * Action to require registration
     */
    public requireRegistration() {
        useUser().onRegistrationRequired()
    }

    /**
     * Action to set new stakes
     */
    public updateStakes(stakeYes: number) {
        useMillion().onSetStakes({
            stakeYes,
            stakeNo: this.getUserMoney() - stakeYes,
        })
    }

    /**
     * Action to set guide completed
     */
    public guideCompleted(value: boolean) {
        useMillion().onSetGuideCompleted(value)
    }

    /**
     * Action to lock the split
     */
    public lock(stakes: { yes: number; no: number }, idQuestion: string) {
        useMillion().onLockSplit({
            stakes,
            idQuestion,
        })
    }

    /**
     * Action to unlock the split
     */
    public async unlock(idQuestion: string) {
        const response = await useMillion().onUnlockSplit({ idQuestion })

        this.updateCurrentQuestion(response)
    }

    /**
     * Checks if there is race question ID
     */
    public isRaceQuestion() {
        return !!(useMillion().getQuestionRaceID || null)
    }

    /**
     * Gets race question ID
     */
    public getQuestionRaceID(): string | null {
        return useMillion().getQuestionRaceID || null
    }

    /**
     * Action to set open question
     */
    public closeOpenQuestion() {
        useMillion().onSetOpenQuestion(false)
    }

    /**
     * Action to opt in
     */
    public optIn() {
        useMillion().onOptIn()
    }

    /**
     * Action to start game
     */
    public startGame() {
        const participantId = useMillion().getParticipantID

        if (participantId !== null) {
            useMillion().onStartGame(participantId)
        }
    }

    /**
     * Gets banner URL
     */
    public getBannerURL() {
        return useMillion().getBannerURL
    }

    /**
     * Gets Landing logo URL
     */
    public getLandingLogoURL() {
        return useMillion().getLandingLogoURL
    }

    /**
     * Gets Gaming logo URL
     */
    public getGamingLogoURL() {
        return useMillion().getGamingLogoURL
    }

    /**
     * Gets background URL
     */
    public getBackgroundURL() {
        return useMillion().getBackgroundURL
    }

    /**
     * Gets game Version
     */
    public getGameVersion() {
        return useMillion().getGameVersion
    }

    /**
     * Gets landing page UI version
     */
    public getLandingPageVersion() {
        return useMillion().getLandingPageVersion
    }

    private updateCurrentQuestion(newQuestion: OBR.TheMillion.CurrentQuestion) {
        useMillion().onSetCurrentQuestion(newQuestion)
    }
}

export const theMillionService = new TheMillionService()
