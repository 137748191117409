import { loadFonts } from '@obr-core/helpers/load-fonts.helpers'

export function loadGoogleFonts(): void {
    loadFonts(
        [
            { family: 'Open Sans', weights: [400, 600, 700] },
            { family: 'Raleway', weights: [700] },
        ],
        {
            url: 'https://fonts.googleapis.com/css2',
            subset: 'latin-ext',
            display: 'swap',
        }
    )
}
