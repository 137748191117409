/**
 * Add click event listener on <a> tags which triggers the router in
 * case it's and in-app link
 * The `route` callback will get the event and the path to navigate to
 * @param route The router callback
 */
export function registerLinkHandler(
    route: (event: MouseEvent | TouchEvent, to: string) => void
): void {
    window.addEventListener('click', (event) => {
        const target = event.target as HTMLLinkElement
        const relativePath: boolean = target.matches('a:not([href*="://"])')
        // target.nodeName !== 'use' is for svg tags that also have href attr
        if (target && target.href && target.nodeName !== 'use') {
            // Some sanity checks taken from vue-router:
            // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
            const {
                altKey,
                ctrlKey,
                metaKey,
                shiftKey,
                button,
                defaultPrevented,
            } = event
            // Don't handle with control keys
            if (metaKey || altKey || ctrlKey || shiftKey) {
                return
            }
            // Don't handle when preventDefault called
            if (defaultPrevented) {
                return
            }
            // Don't handle right clicks
            if (button !== undefined && button !== 0) {
                return
            }
            const url: URL = new URL(target.href)
            const to: string = url.pathname
            const linkTarget: string = target.getAttribute('target') || ''

            // Don't handle absolute path links and `target="_blank"`, open them in new window
            if (!relativePath || /\b_blank\b/i.test(linkTarget)) {
                event.preventDefault()
                window.open(url.href, linkTarget)
                return
            }
            // Finally trigger router, but don't handle same page links/anchors
            if (window.location.pathname !== to && event.preventDefault) {
                route(event, to)
            }
        }
    })
}
