import { postMessageService } from '@obr-core/services/PostMessageService'
import { POST_MESSAGE_ID_HEIGHT } from '@obr-core/config/post-messages'
import { uiStoreService } from '@obr-core/services/store'

export function sendHeightMessage(offsetHeight?: number) {
    let messageHeight: number

    if (offsetHeight) {
        messageHeight = offsetHeight
    } else if (uiStoreService.getIframeHeight()) {
        messageHeight =
            uiStoreService.getIframeHeight() - uiStoreService.getIframeTop()
    } else {
        messageHeight = document.body.offsetHeight
    }

    postMessageService.send(POST_MESSAGE_ID_HEIGHT, messageHeight)
}
