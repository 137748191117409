import { ParticipantStatus } from '@obr-core/config/the-million'

/**
 * Get can user participate
 */
export function canUserParticipate(state: OBR.Store.MillionState) {
    return state.status !== ParticipantStatus.NON_ELIGIBLE
}

/**
 * Get error code
 */
export function getErrorCode(state: OBR.Store.MillionState) {
    return state.error_code
}

/**
 * Get loading
 */
export function isLoading(state: OBR.Store.MillionState) {
    return state.loading
}

/**
 * Get is user logged out
 */
export function isLoggedOut(state: OBR.Store.MillionState) {
    return state.status === ParticipantStatus.LOGGED_OUT
}

/**
 * Get loading split
 */
export function isSplitLoading(state: OBR.Store.MillionState) {
    return state.loading_split
}

/**
 * Get is an end game
 */
export function isEndGame(state: OBR.Store.MillionState) {
    return [
        ParticipantStatus.LOSER,
        ParticipantStatus.WINNER,
        ParticipantStatus.PAID,
        ParticipantStatus.GAME_EXPIRED,
    ].includes(state.status)
}

/**
 * Get is ueser eligible
 */
export function isEligible(state: OBR.Store.MillionState) {
    return state.status === ParticipantStatus.ELIGIBLE
}

/**
 * Get is show leaderboard
 */
export function showLeaderboard(state: OBR.Store.MillionState) {
    return state.show_leaderboard
}

/**
 * Get is guide completed
 */
export function isGuideCompleted(state: OBR.Store.MillionState) {
    return state.guide_completed
}

/**
 * Get is a new game
 */
export function isNewGame(state: OBR.Store.MillionState) {
    return state.status === ParticipantStatus.ACTIVE_FIRST_QUESTION
}

/**
 * Get is a open game
 */
export function isOpenGame(state: OBR.Store.MillionState) {
    return state.error_code === 0
}

/**
 * Get is required turnover
 */
export function isRequiredTurnover(state: OBR.Store.MillionState) {
    return state.status === ParticipantStatus.TURNOVER_REQUIRED
}

export function isDepositRequired(state: OBR.Store.MillionState) {
    return state.status === ParticipantStatus.DEPOSIT_REQUIRED
}
/**
 * Get required deposit field in game object - not user status
 */
export function getRequiredDepositGame(state: OBR.Store.MillionState) {
    return state.required_deposit
}

export function isFreeGamePage(state: OBR.Store.MillionState) {
    return state.status === ParticipantStatus.ACTIVE_VALID_DEPOSIT
}

/**
 * Get is question exist
 */
export function isQuestionExist(state: OBR.Store.MillionState) {
    return state.current_question !== null
}

/**
 * Get is question open
 */
export function isQuestionOpen(state: OBR.Store.MillionState) {
    return state.current_question !== null ? state.current_question.is_open : ''
}

/**
 * Get question answer status
 */
export function isQuestionLocked(state: OBR.Store.MillionState) {
    return state.current_question !== null
        ? state.current_question.is_answered
        : false
}

/**
 * Get current question
 */
export function getQuestion(state: OBR.Store.MillionState) {
    return state.current_question
}

/**
 * Get current question race ID
 */
export function getQuestionRaceID(state: OBR.Store.MillionState) {
    return state.current_question !== null
        ? state.current_question.id_race
        : false
}

/**
 * Get game currency
 */
export function getCurrency(state: OBR.Store.MillionState) {
    return state.prize_currency
}

/**
 * Get current turnover
 */
export function getCurrentTurnover(state: OBR.Store.MillionState) {
    return state.current_turnover
}

/**
 * Get required turnover
 */
export function getRequiredTurnover(state: OBR.Store.MillionState) {
    return state.required_turnover
}

/**
 * Get total number of questions in the game
 */
export function getQuestionsNum(state: OBR.Store.MillionState) {
    return state.num_questions
}

/**
 * Get current question number
 */
export function getQuestionNum(state: OBR.Store.MillionState) {
    return state.question_num
}

/**
 * Get current question text
 */
export function getQuestionText(state: OBR.Store.MillionState) {
    return state.current_question !== null
        ? state.current_question.question
        : ''
}

/**
 * Get current question deadline
 */
export function getQuestionDeadline(state: OBR.Store.MillionState) {
    return state.current_question !== null
        ? state.current_question.deadline
        : null
}

/**
 * Get current question ID
 */
export function getQuestionID(state: OBR.Store.MillionState) {
    return state.current_question !== null
        ? state.current_question.id_question
        : null
}

/**
 * Get current participant ID
 */
export function getParticipantID(state: OBR.Store.MillionState) {
    return state.id_participant
}
/**
 * Get stake yes
 */
export function getStakeYes(state: OBR.Store.MillionState) {
    return state.stake_yes
}

/**
 * Get stake no
 */
export function getStakeNo(state: OBR.Store.MillionState) {
    return state.stake_no
}

/**
 * Get participant balance
 */
export function getParticipantBalance(state: OBR.Store.MillionState) {
    return state.balance
}

/**
 * Get game prize
 */
export function getGamePrize(state: OBR.Store.MillionState) {
    return state.prize_money
}

/**
 * Get game status
 */
export function getParticipantStatus(state: OBR.Store.MillionState) {
    return state.status
}

/**
 * Get last participant balance
 */
export function getLastParticipantBalance(state: OBR.Store.MillionState) {
    return state.past_participants[0] ? state.past_participants[0].balance : ''
}

/**
 * Get last participant ID
 */
export function getLastParticipantID(state: OBR.Store.MillionState) {
    return state.past_participants[0]
        ? state.past_participants[0].id_participant
        : ''
}

/**
 * Get opt in date
 */
export function getOptInDate(state: OBR.Store.MillionState) {
    return state.opt_in
}

/**
 * Get start game date
 */
export function getStartGameDate(state: OBR.Store.MillionState) {
    return state.start_stamp
}

/**
 * Get banner url
 */
export function getBannerURL(state: OBR.Store.MillionState) {
    return state.images.banner
}

/**
 * Get landing logo url
 */
export function getLandingLogoURL(state: OBR.Store.MillionState) {
    return state.images.logos.landing
}

/**
 * Get gaming logo url
 */
export function getGamingLogoURL(state: OBR.Store.MillionState) {
    return state.images.logos.gaming
}

/**
 * Get game background url
 */
export function getBackgroundURL(state: OBR.Store.MillionState) {
    return state.images.background
}

/**
 * Get game version
 */
export function getGameVersion(state: OBR.Store.MillionState) {
    return state.game_ui
}

/**
 * Get landing page version
 */
export function getLandingPageVersion(state: OBR.Store.MillionState) {
    return state.landing_page_ui
}
