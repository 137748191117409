import { routeConfig as appRoutes } from '@/router/routes'
import { router as routerInstance } from '@obr-core/router/Router'
import { onAfterEach, onError, onSuccess } from '@obr-core/router/hooks'
import {
    onAfterEach as onAfterEachMillion,
    onBeforeEach,
    onBeforeResolve,
} from '@/router/hooks'
const router = routerInstance.createRouter(appRoutes)

// Add global navigation guards
router.beforeEach(onBeforeEach)
router.beforeResolve(onBeforeResolve)
router.afterEach(onAfterEach)
router.afterEach(onAfterEachMillion)

// Add global on ready handler and global route error handler
router.isReady().then(onSuccess(router)).catch(onError)

export { router }
