let cacheDeviceInfo: {
    isWindowsPhone: boolean
    isAndroid: boolean
    isIOS: boolean
    androidVersion: string
    iOSVersion: number
}

export function device() {
    if (cacheDeviceInfo !== undefined) {
        return cacheDeviceInfo
    }
    /**
     * Windows Phone 8.1 fakes user agent string to look like Android and iPhone.
     *
     * @type boolean
     */
    const isWindowsPhone = navigator.userAgent.indexOf('Windows Phone') >= 0

    /**
     * Android requires exceptions.
     *
     * @type boolean
     */
    const isAndroid =
        navigator.userAgent.indexOf('Android') > 0 && !isWindowsPhone

    const userAgent = navigator.userAgent.toLowerCase()
    const check = userAgent.match(/android[^\d]*([0-9.]*)/)
    const androidVersion = check ? check[1] : ''

    /**
     * iOS requires exceptions.
     *
     * @type boolean
     */
    const isIOS = /iP(ad|hone|od)/.test(navigator.userAgent) && !isWindowsPhone

    const iOSVersion = navigator.userAgent.match(/OS (\d+)/)

    cacheDeviceInfo = {
        isWindowsPhone: isWindowsPhone,
        isAndroid: isAndroid,
        androidVersion: androidVersion,
        isIOS: isIOS,
        iOSVersion:
            iOSVersion !== null && iOSVersion.length > 1
                ? parseInt(iOSVersion?.[1] || '0')
                : 0,
    }
    return cacheDeviceInfo
}

/**
 * possible to extend to recognize other old devices
 */
export function isOldDevice() {
    // check for ios but possibile extend for android
    const deviceInfo = device()
    return deviceInfo.isIOS && deviceInfo.iOSVersion <= 13
}
