import { defineStore } from 'pinia'

import { TheMillionResource } from '@obr-core/resources/TheMillionResource'

import * as getters from './getters'
import { initialState } from './state'

export const useMillion = defineStore('obr-store/million', {
    state: initialState,
    actions: {
        /**
         * Initialize million game
         *
         * e.g. load once initialy
         */
        async onInit() {
            const theMillionResource = new TheMillionResource()
            this.onSetLoading(true)

            try {
                const response = await theMillionResource.getGame()

                this.onSetErrorCode(0)
                this.onSet(response)
            } catch (response: any) {
                if (response?.error?.message === 'NO_OPEN_GAME') {
                    this.onSetErrorCode(1000)
                } else {
                    this.onSetErrorCode(response?.error?.error_code || 1)
                }
            } finally {
                this.onSetLoading(false)
            }
        },
        /**
         * Lock million split
         *
         * e.g. when clicking on lock button
         */
        async onLockSplit(payload: {
            idQuestion: string
            stakes: {
                yes: number
                no: number
            }
        }) {
            const theMillionResource = new TheMillionResource()

            this.onSetSplitLoading(true)
            this.onSetIsAnswered(true)

            try {
                await theMillionResource.lock(
                    payload.idQuestion,
                    payload.stakes.no,
                    payload.stakes.yes
                )
            } catch (e) {
                this.onSetIsAnswered(false)
            } finally {
                this.onSetSplitLoading(false)
            }
        },
        /**
         * Lock million split
         *
         * e.g. when clicking on unlock button
         */
        async onUnlockSplit(payload: { idQuestion: string }) {
            let response: OBR.TheMillion.CurrentQuestion
            const theMillionResource = new TheMillionResource()

            this.onSetSplitLoading(true)
            this.onSetIsAnswered(false)

            try {
                response = await theMillionResource.unlock(payload.idQuestion)
            } catch (e) {
                this.onSetIsAnswered(true)
            } finally {
                this.onSetSplitLoading(false)
            }

            return response
        },
        /**
         * Opt IN
         *
         * e.g. TODO
         */
        async onOptIn() {
            const theMillionResource = new TheMillionResource()

            this.onSetLoading(true)

            try {
                const response = await theMillionResource.optIn()

                this.onSetErrorCode(0)
                this.onSet(response)
            } catch (e) {
                this.onSetErrorCode(1)
            } finally {
                this.onSetLoading(false)
            }
        },
        /**
         * Start new million game
         *
         * e.g. when clicking on start game on the million page
         */
        async onStartGame(payload: string) {
            const theMillionResource = new TheMillionResource()

            this.onSetLoading(true)

            try {
                const response = await theMillionResource.startGame(payload)

                this.onSetErrorCode(0)
                this.onSet(response)
            } catch (e) {
                this.onSetErrorCode(1)
            } finally {
                this.onSetLoading(false)
            }
        },
        /**
         * Set million data
         *
         * e.g. set million response data from API
         */
        onSet(payload: OBR.TheMillion.GameResponse) {
            this.images = payload.images
            this.landing_page_ui = payload.landing_page_ui
            this.game_ui = payload.game_ui
            this.current_question =
                payload.current_question === null
                    ? null
                    : Object.assign({}, payload.current_question)
            this.id_game = payload.id_game
            this.num_questions = payload.num_questions

            if (payload.participant) {
                this.stake_yes = payload.participant.stake_yes
                this.stake_no = payload.participant.stake_no
                this.id_participant = payload.participant.id_participant
                this.balance = payload.participant.balance
                this.question_num = payload.participant.question_num
                this.status = payload.participant.player_status
                this.current_turnover = payload.participant.current_turnover
                this.start_stamp = payload.participant.start_stamp
                this.opt_in = payload.participant.opt_in
            }

            this.past_participants = [...payload.past_participants]
            this.prize_currency = payload.prize_currency
            this.prize_money = payload.prize_money
            this.required_turnover = payload.required_turnover
            this.error_code = payload.error_code
            this.show_leaderboard = payload.show_leaderboard
            this.required_deposit = payload.required_deposit
        },
        /**
         * Mutate the state of "guide_complited"
         */
        onSetGuideCompleted(payload: boolean) {
            this.guide_completed = payload
        },
        /**
         * Mutate the state of "loading"
         */
        onSetLoading(payload: boolean) {
            this.loading = payload
        },
        /**
         * Mutate the state of "error_code"
         */
        onSetErrorCode(payload: number) {
            this.error_code = payload
        },
        /**
         * Mutate the state of "current_question.is_answered"
         *
         * i.e. only in case if we have current question
         */
        onSetIsAnswered(payload: boolean) {
            if (this.current_question) {
                this.current_question.is_answered = payload
            }
        },
        /**
         * Mutate the state of "loading_split"
         */
        onSetSplitLoading(payload: boolean) {
            this.loading_split = payload
        },
        /**
         * Mutate the state of stakes
         *
         * i.e. "stake_yes" and "stake_no"
         */
        onSetStakes(payload: { stakeYes: number; stakeNo: number }) {
            this.stake_yes = payload.stakeYes
            this.stake_no = payload.stakeNo
        },
        /**
         * Mutate the state of "current_question.is_open"
         *
         * i.e. only in case if we have current question
         */
        onSetOpenQuestion(payload: boolean) {
            if (this.current_question !== null) {
                this.current_question.is_open = payload
            }
        },
        onSetCurrentQuestion(payload: OBR.TheMillion.CurrentQuestion) {
            this.current_question = payload
        },
    },
    getters,
})
