<script lang="ts">
export default {
    name: 'ObrLoading',
}
</script>

<script setup lang="ts">
export interface Props {
    background?: boolean
    color?: string
}

const props = withDefaults(defineProps<Props>(), {
    background: false,
    color: '',
})

function style(i: number): OBR.Vue.StyleValue {
    return {
        backgroundColor: props.color,
        animationDelay: `${[-0.32, -0.16, 0][i]}s`,
    }
}
</script>

<template>
    <div
        class="animate-loader-fadein obr-loading-loader absolute left-2/4 top-2/4 w-20 -translate-x-1/2 -translate-y-1/2 rounded-full py-1 text-center opacity-0"
        :class="background && 'bg-[--tooltip-background]'"
    >
        <div
            v-for="i in 3"
            :key="i"
            class="animate-dot relative mx-1 mt-0.5 inline-block h-4 w-4 rounded-full bg-[--srf-main]"
            :style="style(i)"
        ></div>
    </div>
</template>
<style lang="scss">
.obr-loading-loader {
    animation-delay: 0.25s;
}
</style>
