<script lang="ts">
export default {
    name: 'ObrMain',
}
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const isMediaLayout = computed(() => route.meta.title === 'Media')
</script>

<template>
    <main
        class="laptop:mx-auto min-w-0"
        :class="!isMediaLayout && 'laptop:container'"
    >
        <slot></slot>
    </main>
</template>
