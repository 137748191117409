import { I18N_MANIFEST_URL } from '@obr-core/config/i18n'
import { fetchWithTimeout } from '@obr-core/lib/fetch-with-timeout'

export function loadDictionaryManifest(): Promise<OBR.I18n.DictionaryHashmap> {
    const date = new Date()
    const YYYY = String(date.getFullYear())
    const MM = String(date.getMonth() + 1).padStart(2, '0')
    const DD = String(date.getDate()).padStart(2, '0')
    const freshManifest = `${I18N_MANIFEST_URL}?${YYYY}-${MM}-${DD}`

    return fetchWithTimeout(freshManifest, {
        timeout: 2000,
        abortMessage: 'manifest.json file took more than 2s to load',
    }).then((v) => v.json())
}
