import ObrCheckbox from 'components/Checkbox/Checkbox.vue'
import ObrMain from 'components/Layout/Main/Main.vue'
import ObrLoadingOverlay from 'components/LoadingOverlay/LoadingOverlay.vue'
import ObrSvgIcon from 'components/SvgIcon/SvgIcon.vue'

/**
 * Register basic components globally in Vue
 * This has to be called before root Vue instance is created (with new Vue)
 */
export function registerGlobalComponents(app: OBR.Global.App) {
    app.component('ObrMain', ObrMain)
    app.component('ObrLoadingOverlay', ObrLoadingOverlay)
    app.component('ObrCheckbox', ObrCheckbox)
    app.component('ObrSvgIcon', ObrSvgIcon)

    return app
}
