<script lang="ts">
export default {
    name: 'ObrLoadingOverlay',
}
</script>

<script setup lang="ts">
import { ref, Ref, watch, onUnmounted } from 'vue'

import { isStandalone } from '@obr-core/helpers/app.helpers'

import ObrLoading from '@obr-ui/components/Loading/Loading.vue'
import ObrLogo from '@obr-ui/components/Logo/Logo.vue'

export interface Props {
    duration?: number
    isLoading: boolean
    fullPage?: boolean
    transparent?: boolean
    semiTransparent?: boolean
    showLogo?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    duration: 500,
    isLoading: true,
    fullPage: false,
    transparent: false,
    semiTransparent: false,
    showLogo: !isStandalone(),
})

const isHidden = ref(!props.isLoading)
const timeoutId: Ref<null | ReturnType<typeof setTimeout>> = ref(null)
const start = ref(0)
const end = ref(0)

watch(
    () => props.isLoading,
    (val: boolean) => {
        if (val) {
            isHidden.value = false
            start.value = new Date().getTime()
        } else {
            end.value = new Date().getTime()

            if (!isHidden.value) {
                clearTimeoutId()
                const diff = end.value - start.value

                if (diff < props.duration) {
                    timeoutId.value = setTimeout(() => {
                        if (!props.isLoading) {
                            isHidden.value = true
                        }
                    }, props.duration - diff)
                } else {
                    isHidden.value = true
                }
            }
        }
    },
    { immediate: true }
)

const bodyElement = document.querySelector('body')
watch(
    () => isHidden.value,
    (val: boolean) => {
        if (val && bodyElement) {
            bodyElement.classList.remove('no-scroll')
        } else if (bodyElement) {
            bodyElement.classList.add('no-scroll')
        }
    },
    { immediate: true }
)

function clearTimeoutId() {
    if (timeoutId.value !== null) {
        clearTimeout(timeoutId.value)
    }
}

onUnmounted(() => {
    clearTimeoutId()
})
</script>
<template>
    <div
        v-if="isLoading"
        class="inset-0 z-50 h-screen opacity-100"
        :class="[
            isHidden && 'animate-loader-fadeout',
            fullPage && 'fixed',
            transparent
                ? 'bg-transparent'
                : semiTransparent
                ? 'bg-srf-overlay'
                : 'bg-[--srf-main]',
        ]"
        data-testid="loader"
    >
        <div
            v-if="showLogo"
            class="animate-loader-fadein obr-loading-overlay-logo absolute left-1/2 top-1/2 opacity-0"
        >
            <obr-logo></obr-logo>
        </div>
        <obr-loading background></obr-loading>
    </div>
</template>
<style lang="scss">
.obr-loading-overlay-logo {
    transform: translate(-50%, -100%);
    padding-bottom: 2.5rem;
}
</style>
