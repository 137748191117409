<template>
    <obr-app-container v-resize="sendHeightMessage"></obr-app-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { appStoreService } from '@obr-core/services/store'
import { registerLinkHandler } from '@obr-core/utils/link.handler'
import { sendHeightMessage } from '@obr-ui/helpers/post-messages.helpers'
import { resize } from '@obr-ui/directives/resize'
import { postMessageService } from '@obr-core/services/PostMessageService'
import { focusOnlyKeyboard } from '@obr-ui/utils/focus-outline.handler'
import { POST_MESSAGE_ID_NAVIGATION } from '@obr-core/config/post-messages'

import ObrAppContainer from 'components/AppContainer/AppContainer.vue'

export default defineComponent({
    name: 'ObrApp',
    components: {
        ObrAppContainer,
    },
    directives: {
        resize,
    },
    beforeCreate() {
        // Dispatch lifecycle: App before everything
        appStoreService.init()
    },
    created() {
        postMessageService.addListener(POST_MESSAGE_ID_NAVIGATION, (data) => {
            this.$router.push(data.payload)
        })
        // Register global link handler
        registerLinkHandler((event, to) => {
            event.preventDefault()
            this.$router.push(to)
        })
        // Dispatch lifecycle: App is created
        appStoreService.created()
    },
    mounted() {
        focusOnlyKeyboard()
        // Dispatch lifecycle: App is mounted
        appStoreService.mounted()
    },
    methods: {
        sendHeightMessage,
    },
})
</script>
