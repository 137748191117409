import { getItem } from '@obr-core/lib/storage.manager'
import {
    MILLION_STORAGE_KEY_GUIDE_COMPLETED,
    ParticipantStatus,
} from '@obr-core/config/the-million'
import { Currency } from '@obr-core/config/settings'

export function initialState(): OBR.Store.MillionState {
    return {
        images: {
            banner: '',
            logo: null,
            background: null,
        },
        landing_page_ui: 'v1',
        game_ui: 'v1',
        current_question: null,
        id_game: '',
        num_questions: 0,
        stake_yes: 0,
        stake_no: 0,
        can_participate: false,
        id_participant: null,
        balance: 0,
        question_num: 0,
        status: ParticipantStatus.LOGGED_OUT,
        current_turnover: 0,
        past_participants: [],
        prize_currency: Currency.EUR,
        prize_money: 0,
        required_turnover: 0,
        error_code: 0,
        loading: true,
        loading_split: false,
        guide_completed: !!getItem(MILLION_STORAGE_KEY_GUIDE_COMPLETED),
        start_stamp: null,
        opt_in: null,
        show_leaderboard: false,
        required_deposit: false,
    }
}
