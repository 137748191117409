import { Brand } from '@obr-core/config/env'
import { OddsFormat } from '@obr-core/config/settings'
import { StreamType, Provider, MediaType } from '@obr-core/config/media'
import { RaceType } from '@obr-core/config/race'
import { device } from '@obr-core/helpers/device.helpers'
import { StreamsService } from '@obr-core/services/api'
import { appStoreService, userStoreService } from '@obr-core/services/store'
import { isTouchScreen } from './app.helpers'

const streamsService = StreamsService.getInstance()
const deviceHelper = device()

/**
 * Check if country is allowed
 */
export function isCountryAllowed(
    country: string,
    countries: string[],
    filterType: string | null
) {
    // check if country is in countries
    const isInCountries = countries.includes(country)

    if (filterType === 'DIS') {
        // if country is in countries it should be disallowed
        return !isInCountries
    }

    if (filterType === 'ALW') {
        // if country is in countries it should be allowed
        return isInCountries
    }

    return true
}

export function supportsWebRTC() {
    if (
        window.RTCPeerConnection &&
        typeof window.RTCPeerConnection === 'function'
    ) {
        return true
    }
    return false
}

export function isIframe(provider: Provider, streamType: StreamType) {
    return (
        (provider === Provider.EGT &&
            (streamType === StreamType.LIV || !isTouchScreen())) ||
        (provider === Provider.SISTV && supportsWebRTC())
    )
}

export function getStreamInformation(
    stream: OBR.Streams.StreamRequired,
    mobile: boolean = true,
    idResource = 0
) {
    const resource = stream.resources[idResource]

    if (resource) {
        return checkData(stream, mobile, resource)
    }
}

async function checkData(
    stream: OBR.Streams.StreamRequired,
    mobile: boolean,
    resource: OBR.Streams.ChannelResource
) {
    if (stream.streamType === StreamType.LIV) {
        if (stream.provider === Provider.PFG) {
            return getStreamUrl(stream, mobile).then((resStreamUrl) => {
                if (resStreamUrl) {
                    return handlePFG(resStreamUrl).then((infoPFG) => {
                        infoPFG.script = true
                        return infoPFG
                    })
                }
            })
        }

        if (stream.provider === Provider.TV1 || stream.idChannel == '18') {
            return createPlayer(stream, resource)
        }

        // Hexaglobe, At The Races, EasyGate, SIS or SKY
        if (
            [
                Provider.ATR,
                Provider.EGT,
                Provider.HEX,
                Provider.SISTV,
                Provider.SKY,
                Provider.YOUTUBE,
            ].includes(stream.provider)
        ) {
            return getStreamUrl(stream, mobile).then((resStreamUrl) => {
                if (resStreamUrl) {
                    return createPlayer(stream, resource, resStreamUrl)
                }
            })
        }
    }

    // archive video
    else {
        if ([StreamType.MP4, StreamType.MSC].includes(stream.streamType)) {
            if (
                [Provider.ATR, Provider.PFG, Provider.EGT].includes(
                    stream.provider
                )
            ) {
                return getStreamUrl(stream, mobile).then((resStreamUrl) => {
                    if (resStreamUrl) {
                        return createPlayer(stream, resource, resStreamUrl)
                    }
                })
            }

            if ([Provider.TV1, Provider.HEX].includes(stream.provider)) {
                return createPlayer(stream, resource, undefined)
            }
        }
    }

    throw new Error('generic.h_generic_error')
}

function getStreamUrl(stream: OBR.Streams.StreamRequired, mobile: boolean) {
    return streamsService.getStreamResource({
        id_channel: stream.idChannel,
        stream_type: stream.streamType,
        mobile,
        id_race: stream.idRace,
    })
}
function handlePFG(infoPFG: any) {
    return Promise.resolve(infoPFG)
}

function createPlayer(
    stream: OBR.Streams.StreamRequired,
    resource: OBR.Streams.ChannelResource,
    mediaResource?: OBR.Streams.StreamResourceResponse
) {
    const res = {
        sources: [],
        audio: false,
        iframe: false,
        script: false,
        requestId: -1,
        idChannel: mediaResource?.id_channel,
    }

    let http = ''
    let rtmp = ''
    let isIOS

    if (
        [Provider.ATR, Provider.EGT, Provider.SISTV, Provider.SKY].includes(
            stream.provider
        )
    ) {
        http = mediaResource?.stream_url || ''

        if (isIframe(stream.provider, stream.streamType)) {
            res.iframe = true
            http = mediaResource?.stream_player || ''
        }
    } else if (stream.provider === Provider.PFG) {
        http = mediaResource?.archive_url || ''
    } else if (stream.provider === Provider.YOUTUBE) {
        http = mediaResource?.stream_player || ''
    } else {
        if (resource.technology === 'MP3') {
            res.audio = true
        }
        ;({ http, rtmp } = constructStreamUrl(stream, resource, mediaResource))
        isIOS = deviceHelper.isIOS
    }

    if (stream.streamType === StreamType.LIV) {
        res.requestId = mediaResource?.request_id || -1
    }

    // on iOS7 we redirect the browser to the streaming url,
    // once the user clicks done he is redirected to racebets again.
    // from iOS8 this doesn't happens unless the streaming starts from the current page.
    // Android devices below 4.5.0 don't go full screen with videojs
    if (
        (isIOS && deviceHelper.iOSVersion < 8) ||
        (deviceHelper.isAndroid &&
            deviceHelper.androidVersion &&
            parseFloat(deviceHelper.androidVersion) < 4.5)
    ) {
        //open in new window
        window.open(http, '_blank')
    }

    const sources = []

    if (http) {
        sources.push({
            src: http,
            type: getMediaSourceType(stream.provider, stream.streamType),
        })
    }

    if (rtmp) {
        sources.push({
            src: rtmp,
            type: getMediaSourceType(stream.provider, stream.streamType),
        })
    }

    return { ...res, sources }
}

/**
 * Get source type for specific stream type
 *
 * e.g. used in "<video></video>" tag
 */
function getMediaSourceType(provider: Provider, streamType: StreamType) {
    if (provider === Provider.ATR || streamType === StreamType.LIV) {
        return 'application/x-mpegURL'
    }

    //For archive videos on mobile devices, we use the m3u8 format
    if (
        provider === Provider.EGT &&
        streamType === StreamType.MSC &&
        isTouchScreen()
    ) {
        return 'application/x-mpegURL'
    }

    return 'video/mp4'
}

/**
 * Code is copied form legacy files
 *
 * i.e. sunshine/mobilev2/src/brands/racebets/js/components/media/media.js
 */
function constructStreamUrl(
    stream: OBR.Streams.StreamRequired,
    resource: OBR.Streams.ChannelResource,
    mediaResource?: OBR.Streams.StreamResourceResponse
) {
    const res = {
        http: '',
        rtmp: '',
    }
    // Hotfix MNT-2383
    if (stream.idChannel === '18') {
        return {
            http: 'http://wowzaprodhd50-lh.akamaihd.net/i/5e08aadd_1@147070/master.m3u8',
            rtmp: 'http://wowzaprodhd50-lh.akamaihd.net/z/5e08aadd_1@147070/manifest.f4m',
        }
    }

    // Hexaglobe
    if (
        stream.provider === Provider.HEX &&
        stream.streamType === StreamType.LIV
    ) {
        res.http = mediaResource?.stream_url || ''
        return res
    }

    // EGT
    if (
        stream.provider === Provider.EGT &&
        stream.streamType !== StreamType.LIV
    ) {
        res.http = mediaResource?.stream_url || ''
        return res
    }

    let path = ''
    if (
        stream.provider === Provider.TV1 &&
        stream.streamType === StreamType.LIV
    ) {
        path = stream.folder + resource.file
    } else if (stream.streamType === StreamType.LIV) {
        path = resource.file
    } else {
        // archive videos

        const streamPath = stream.archiveLink.split('/')
        const raceDate = streamPath[3]
            ? new Date(
                  `${streamPath[3].substr(0, 4)}-${streamPath[3].substr(
                      4,
                      2
                  )}-${streamPath[3].substr(6)}`
              )
            : new Date()
        const twoDayAgo = new Date()
        // remove two days
        twoDayAgo.setDate(twoDayAgo.getDate() - 2)

        if (stream.provider === Provider.HEX || raceDate < twoDayAgo) {
            path =
                'https://vod.racebets.com/' +
                streamPath[2] +
                '/' +
                streamPath[3].substr(0, 4) +
                '/' +
                streamPath[3].substr(4, 2) +
                '/' +
                streamPath[3].substr(6) +
                '/' +
                streamPath[4] +
                '/' +
                streamPath[5]

            res.http = path
            return res
        }

        path = stream.archiveLink
    }

    const token = '7RqTJHOTi9927014'

    res.rtmp = `rtmp://xapp990314496c27014.s.${
        stream.streamType === StreamType.LIV ? 'l' : 'o'
    }.f.lb.core-cdn.net/${token}/27014rci/flv:${path}`
    res.http = `http://xapp990314496c27014.f.${
        stream.streamType === StreamType.LIV ? 'l' : 'o'
    }.z.lb.core-cdn.net/${
        stream.streamType === StreamType.LIV ? '10049xrci' : '10062xrciod'
    }/_definst_/27014rci/${path}/playlist.m3u8?key=${token}`

    return res
}

/**
 * Filter streams channels from API
 *
 * i.e. conditions for showing channels:
 * * remove channels that are expired
 * * hide dogs-only channels if filtered out
 * * check if stream is allowed based on user country
 * * if stream is limited to one odds format, hide it if it doesn't match user odds format (only support fractional and base1 and default to base1)
 */
export function filterStreamsChannels(channels: OBR.Streams.Channel[]) {
    const currentTimestampInSeconds = Date.now() / 1000

    return channels.filter((channel) => {
        // expired stream
        if (currentTimestampInSeconds > channel.end_time) {
            return false
        }

        // hide dogs-only channels if filtered out
        if (
            channel.race_types &&
            channel.race_types.length === 1 &&
            channel.race_types[0] === RaceType.GREYHOUND
        ) {
            if (
                appStoreService.websiteTLD()?.toLowerCase() === 'de' ||
                appStoreService.brand() === Brand.SUAPOSTA
            ) {
                return false
            }
        }

        // check if the stream is allowed based on user country
        if (
            !isCountryAllowed(
                userStoreService.mediaCountry() || appStoreService.ipCountry(),
                channel.filter_countries,
                channel.filter_type
            )
        ) {
            return false
        }

        // if stream is limited to one odds format, hide it if it doesn't match user odds format (only support fractional and base1 and default to base1)
        if (
            channel.odds_format &&
            [OddsFormat.FRACTIONAL, OddsFormat.DECIMAL].includes(
                channel.odds_format
            )
        ) {
            const userFormat =
                userStoreService.oddsFormat() === OddsFormat.FRACTIONAL
                    ? OddsFormat.FRACTIONAL
                    : OddsFormat.DECIMAL

            if (userFormat !== channel.odds_format) {
                return false
            }
        }

        return true
    })
}

export function defaultMedia(): OBR.Streams.Media {
    return {
        archive_link: '',
        filters_live: {
            type: '',
            apply_to_archive: false,
        },
        folder: '',
        id_channel: '',
        id_race: '',
        malfunction_info: 0,
        media_type: MediaType.VID,
        provider: Provider.ATR,
        resources: [],
        start_time: 0,
        stream_title: '',
        stream_type: StreamType.LIV,
    }
}
