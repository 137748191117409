<template>
    <div class="relative w-full" v-resize="onBrowserResize">
        <router-view></router-view>
        <obr-loading-overlay
            :is-loading="isLoading"
            full-page
            show-logo
        ></obr-loading-overlay>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { resize } from '@obr-ui/directives/resize'
import { appStoreService, uiStoreService } from '@obr-core/services/store'
import { theMillionService } from '@obr-core/services/TheMillionService'
import { loadGoogleFonts } from './composable'
import {
    isStandalone as isStandaloneHelper,
    isIframe as isIframeHelper,
} from '@obr-core/helpers/app.helpers'
import {
    isDesktop,
    isLaptop,
    isTabletLandscape,
    isTabletPortrait,
} from '@obr-ui/helpers/breakpoints'

import ObrLoadingOverlay from '@obr-ui/components/LoadingOverlay/LoadingOverlay.vue'

export default defineComponent({
    name: 'AppContainer',
    components: {
        ObrLoadingOverlay,
    },
    data() {
        return {
            timeoutResize: null as null | ReturnType<typeof setTimeout>,
        }
    },
    directives: {
        resize,
    },
    computed: {
        isLoading() {
            return appStoreService.isLoading() || theMillionService.isLoading()
        },
        isIframe() {
            return isIframeHelper()
        },
        isStandalone() {
            return isStandaloneHelper()
        },
    },
    mounted() {
        if (this.isStandalone) return
        loadGoogleFonts()
    },
    created() {
        this.afterBrowserResize()
    },
    methods: {
        onBrowserResize() {
            this.timeoutResize != null && clearTimeout(this.timeoutResize)
            this.timeoutResize = setTimeout(
                this.afterBrowserResize.bind(this),
                300
            )
        },
        afterBrowserResize() {
            uiStoreService.setTabletPortrait(isTabletPortrait())
            uiStoreService.setTabletLandscape(isTabletLandscape())
            uiStoreService.setLaptop(isLaptop())
            uiStoreService.setDesktop(isDesktop())
        },
    },
})
</script>
