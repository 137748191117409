import { HTTP_REST_V2 } from '@obr-core/config/http'
import { http } from '@obr-core/lib/http'
import { errorLogger } from '@obr-core/services/ErrorLogger'
/**
 * The Million resource
 */
export class TheMillionResource {
    private readonly apiPath: string = `${HTTP_REST_V2}/themillion`

    /**
     * Get the million game
     */
    public async getGame(): Promise<OBR.TheMillion.GameResponse> {
        try {
            const res = await http.get(this.apiPath)
            return this.parseGame(res)
        } catch (e: any) {
            errorLogger.createException(e)
            throw e?.response?.data
        }
    }

    /**
     * Get the million history
     */
    public async getHistory(): Promise<OBR.TheMillion.HistoryResponse | null> {
        try {
            const res = await http.get(`${this.apiPath}/history`)
            return this.parseHistory(res)
        } catch (e) {
            errorLogger.createException(e)
            // no-op
        }

        return null
    }

    /**
     * Get participant history
     * @param idParticipant Participant id
     */
    public async getParticipantHistory(
        idParticipant: string
    ): Promise<OBR.TheMillion.ParticipantHistoryResponse | null> {
        try {
            const res = await http.get(
                `${this.apiPath}/${idParticipant}/history`
            )

            return this.parseParticipantHistory(res)
        } catch (e) {
            errorLogger.createException(e)
            // no-op
        }

        return null
    }

    /**
     * Get the million Leaderboardstory
     */
    public async getLeaderboard(): Promise<OBR.TheMillion.LeaderboardResponse | null> {
        try {
            const res = await http.get(`${this.apiPath}/leaderboard`)
            return this.parseLeaderboard(res)
        } catch (e) {
            errorLogger.createException(e)
            // no-op
        }

        return null
    }

    /**
     * Lock a question
     * @param idQuestion Question id
     * @param stakeNo Stake "no" value
     * @param stakeYes Stake "yes" value
     */
    public async lock(idQuestion: string, stakeNo: number, stakeYes: number) {
        try {
            return await http.post(`${this.apiPath}/answer/${idQuestion}`, {
                stake_no: stakeNo,
                stake_yes: stakeYes,
            })
        } catch (e) {
            errorLogger.createException(e)
            throw e
        }
    }

    /**
     * Unlock a question
     * @param idQuestion Question id
     */
    public async unlock(idQuestion: string) {
        try {
            const response = await http.delete<OBR.TheMillion.CurrentQuestion>(
                `${this.apiPath}/answer/${idQuestion}`
            )

            return response.data
        } catch (e) {
            throw e
        }
    }

    /**
     * Opt in for game
     */
    public async optIn() {
        try {
            const res = await http.post(`${this.apiPath}`)
            return this.parseGame(res)
        } catch (e) {
            errorLogger.createException(e)
            throw e
        }
    }

    /**
     * Start new game
     */
    public async startGame() {
        try {
            const res = await http.put(`${this.apiPath}`)
            return this.parseGame(res)
        } catch (e) {
            errorLogger.createException(e)
            throw e
        }
    }

    /**
     * Parse the million game
     * @param res API response
     */
    private parseGame(
        res: OBR.Http.AjaxResponse<OBR.TheMillion.GameResponse>
    ): OBR.TheMillion.GameResponse {
        return { ...res.data }
    }

    /**
     * Parse the million history
     * @param res API response
     */
    private parseHistory(
        res: OBR.Http.AjaxResponse<OBR.TheMillion.HistoryResponse>
    ): OBR.TheMillion.HistoryResponse {
        return [...res.data]
    }

    /**
     * Parse the million leaderboard
     * @param res API response
     */
    private parseLeaderboard(
        res: OBR.Http.AjaxResponse<OBR.TheMillion.LeaderboardResponse>
    ): OBR.TheMillion.LeaderboardResponse {
        return [...res.data]
    }

    /**
     * Parse participant history
     * @param res API response
     */
    private parseParticipantHistory(
        res: OBR.Http.AjaxResponse<OBR.TheMillion.ParticipantHistoryResponse>
    ): OBR.TheMillion.ParticipantHistoryResponse {
        return [...res.data]
    }
}
