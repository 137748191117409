export async function fetchWithTimeout(
    resource: string,
    options: { timeout: number; abortMessage?: string } = { timeout: 2000 }
) {
    const { timeout, abortMessage } = options

    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(abortMessage), timeout)

    const response = await fetch(resource, {
        signal: controller.signal,
    })

    clearTimeout(id)

    return response
}
