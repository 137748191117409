export function defineFonts(
    fonts: OBR.Fonts.Font[],
    options: OBR.Fonts.FontOptions
): string {
    return fonts.reduce((previous, current, index) => {
        const separator = index === 0 ? '?' : '&'
        const family = current.family.replace(' ', '+')
        const weights = current.weights.join(';')

        return previous + `${separator}family=${family}:wght@${weights}`
    }, options.url)
}

function defineFontOptions(
    url: string,
    options: OBR.Fonts.FontOptions
): string {
    if (!options) return url

    return Object.entries(options).reduce((previous, [key, value]) => {
        if (key && value && key !== 'url') {
            previous += `&${key}=${value}`
        }

        return previous
    }, url)
}

function appendStyleTagToHead(content: string): void {
    const style = document.createElement('style')
    style.innerText = content
    document.head.append(style)
}

function buildCSSImportPath(path: string): string {
    return `@import url('${path}');`
}

export function loadFonts(
    fonts: OBR.Fonts.Font[],
    options: OBR.Fonts.FontOptions
): void {
    const importPath = defineFontOptions(defineFonts(fonts, options), options)
    const content = buildCSSImportPath(importPath)
    appendStyleTagToHead(content)
}
